import React, { useEffect, useState } from "react";
import SVG from 'react-inlinesvg';
import classNames from "classnames";

import Header from "./header";
import { stripString } from "../utils";

const Logos = ({ component, className , sectionBackgroundColor, logosBgColor, isIndustriesPage }) => {
  const anchorText = component.header.anchorText; 
  const [displayedLogos, setDisplayedLogos] = useState(component.logos.slice(0, 8));
  const [availableLogos, setAvailableLogos] = useState(component.logos.slice(8));
  const [replacedActiveLogoIndex, setLogoReplacedIndex] = useState(undefined);
  
  useEffect(() => {
    const swapLogo = () => {
      if (availableLogos.length > 0) {
        const randomIndex = Math.floor(Math.random() * availableLogos.length);
        const randomLogo = availableLogos[randomIndex];
        const replaceIndex = Math.floor(Math.random() * displayedLogos.length);
        const newDisplayedLogos = [...displayedLogos];
        const [replacedLogo] = newDisplayedLogos.splice(replaceIndex, 1, randomLogo);
        setLogoReplacedIndex(replaceIndex);
        setTimeout(() => {
          setDisplayedLogos(newDisplayedLogos);
          setAvailableLogos([...availableLogos, replacedLogo].filter(logo => logo !== randomLogo));
          setLogoReplacedIndex(undefined);
        }, 250); // Adjust timing as needed
      }
    };

    const intervalId = setInterval(swapLogo, 3000); // Swap every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [displayedLogos, availableLogos]);

  if (!(component.logos && component.logos.length > 0)) {
    return null
  }

  return (
    <section 
      className={classNames(
        'section services-logos', 
        {
          [className]: className
        }
      )} 
      id={anchorText && stripString(anchorText) || ''} 
      style={{backgroundColor: sectionBackgroundColor}}
    >
      {component.header && (
          <Header heading={component.header.heading} supheading={component.header.supheading} isIndustriesPage={isIndustriesPage}/>
      )}
      {component.logos.length > 0 && (
        <div className="container">
          <div className="row">
            <div className="offset-md-1 col-md-10">
              <ul className="services-logos__list row">
                {displayedLogos.map((logo, index) => {
                  
                  return (
                    <li key={index} className={classNames("services-logos__item services-logos__item--spaced col-6 col-md-3")}  title={logo?.alternativeText} style={logosBgColor === "color" ? {} : {fill: logosBgColor }}>
                      <SVG
                        src={logo.url}
                        className={classNames("services-logos__svg", {"services-logos__svg--rotate": index === replacedActiveLogoIndex })} // Apply animation class conditionally
                      />  
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </section>   
  );
}

export default Logos


